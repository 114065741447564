export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoiZ2V0YmVudG8iLCJhIjoiY2s5dmwzdDJzMDBjMjNmbmp4Z21hOGE5OSJ9.K9HkkmazQcyhd1Ok3w6Q6w';
export const MAPBOX_GEOCODER = 'MapboxGeocoder';
export const MAPBOX_GEOCODER_ID = `#${MAPBOX_GEOCODER}`;
export const MAPBOX_GEOCODER_INPUT_ID = 'MapboxGeocoderInput';
export const MAPBOX_GEOCODER_INPUT_CLASS = '.mapboxgl-ctrl-geocoder--input';
export const MAPBOX_GEOCODER_INPUT_PLACEHOLDER = 'Enter a location';

export const MAPBOX_GEOCODER_LABEL = 'Enter zip code, city, or full address';
export const MAPBOX_GEOCODER_NOT_AVAILABLE_MESSAGE =
  'Search is not available at this time.';
